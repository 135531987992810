<template>
  <v-container>
    <v-snackbar top v-model="error" color="red">
      <v-icon>mdi-alert-circle-outline</v-icon> <span class="font-weight-bold">Bitte alle Felder ausfüllen!</span>
    </v-snackbar>
    <v-snackbar top v-model="copied" color="success">
      <v-icon> mdi-checkbox-marked-circle</v-icon> <span class="font-weight-bold">Der erstellte Texte wurde in die Zwischenablage kopiert!</span>
    </v-snackbar>
    <v-card>
      <v-card-title>
        Einleitung        
      </v-card-title>
      <v-card-actions>
        <v-row align="center">
          <v-col cols="4">
            Bei der Immobilie handelt es sich um
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="selectedEinleitung"
              :items="selectedEinleitungItems"
              item-text="text"
              item-value="text"
              label="Bitte auswählen..."
              return-object
              single-line
              outlined
            ></v-select>
          </v-col>
          <v-col cols="1">
          in
          </v-col>
          <v-col cols="3">
            <!-- <v-text-field
              v-model="city"
              label="Stadt"
              outlined
            ></v-text-field> -->
            <v-autocomplete
              v-model="city"
              :items="cityArray"
              outlined
              label="Stadt"
            ></v-autocomplete>

          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider ></v-divider>
      <v-card-title>
        Heizung        
      </v-card-title>
      <v-card-actions>
        <v-row align="center">
          <v-col cols="2">
            Der Wohnbereich wird über eine
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="selectedHeizung"
              :items="selectedHeizungItems"
              item-text="text"
              item-value="text"
              label="Bitte auswählen..."
              return-object
              single-line
              outlined
            ></v-select>
          </v-col>
          <v-col cols="5">
          mit Wärme versorgt, die Heizungsanlage wird in regelmäßigen Abständen gewartet und befindet sich in
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="selectedZustand"
              :items="selectedZustandItems"
              item-text="text"
              item-value="text"
              label="Bitte auswählen..."
              return-object
              single-line
              outlined
            ></v-select>
          </v-col>
          <v-col cols="1">
          Zustand.
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider ></v-divider>
      <v-card-title>
        Fenster        
      </v-card-title>
      <v-card-actions>
        <v-row align="center">
          <v-col cols="3">
            Bei den Fenstern handelt es sich um
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="selectedFenster"
              :items="selectedFensterItems"
              item-text="text"
              item-value="text"
              label="Bitte auswählen..."
              return-object
              single-line
              outlined
            ></v-select>
          </v-col>
          <v-col cols="3">
            mit 
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="selectedGlas"
              :items="selectedGlasItems"
              item-text="text"
              item-value="text"
              label="Bitte auswählen..."
              return-object
              single-line
              outlined
            ></v-select>
          </v-col>
        </v-row> 
      </v-card-actions>
      <v-divider ></v-divider>
      <v-card-title>
        Sanitärräume        
      </v-card-title>
      <v-card-actions>
        <v-row align="center">
          <v-col cols="12">
            <v-select
              v-model="selectedWC"
              :items="selectedWCItems"
              item-text="text"
              item-value="text"
              label="Bitte auswählen..."
              return-object
              single-line
              outlined
            ></v-select>
          </v-col>
        </v-row> 
      </v-card-actions>
      <v-card-actions>
        <v-row align="center">
          <v-col cols="1">
            <v-checkbox
              v-model="enabledGuestWC"
            ></v-checkbox>
          </v-col>
          <v-col cols="5">
            Zusätzlich gibt es ein Gäste-WC mit 
          </v-col>
          <v-col cols="6">
            <v-select
                :disabled="!enabledGuestWC"
                v-model="selectedGuestWC"
                :items="selectedGuestWCItems"
                item-text="text"
                item-value="text"
                label="Bitte auswählen..."
                return-object
                single-line
                outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider ></v-divider>
      <v-card-title>
        Sonstiges       
      </v-card-title>
      <v-card-actions>
        <v-row align="center">
          <v-col cols="1">
            <v-checkbox
              v-model="enabledBalkon"
            ></v-checkbox>
          </v-col>
          <v-col cols="11">
            Die Immobilie verfügt über einen Balkon mit ausreichender Sitzfläche. 
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions>
        <v-row align="center">
          <v-col cols="1">
            <v-checkbox
              v-model="enabledPlaetze"
            ></v-checkbox>
          </v-col>
          <v-col cols="2">
            Zusätzlich 
          </v-col>
          <v-col cols="5">
            <v-select
                :disabled="!enabledPlaetze"
                v-model="selectedPlaetze"
                :items="selectedPlaetzeItems"
                item-text="text"
                item-value="text"
                label="Bitte auswählen..."
                return-object
                single-line
                outlined
            ></v-select>
          </v-col>
          <v-col cols="4">
            vorhanden. 
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-actions>
        <v-row align="center">
          <v-col cols="1">
            <v-checkbox
              v-model="enabledPraktisch"
            ></v-checkbox>
          </v-col>
          <v-col cols="11">
            Die Immobilie ist äußerst praktisch geschnitten, die Wohnraumaufteilung ist sinnvoll angeordnet und absolut zeitgemäß.
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider ></v-divider>
      <v-card-title>
        Zusätzliche Angaben        
      </v-card-title>
      <v-card-text>
        <v-textarea
          outlined
          label="Angaben"
          auto-grow
          v-model="additionalInfo"
        ></v-textarea>
      </v-card-text>
      <v-divider ></v-divider>
      <v-card-title>
        Zusammenfassung        
      </v-card-title>
      <v-card-actions>
        <v-row align="center">
          <v-col cols="3">
            Insgesamt befindet sich die Immobilie in einem 
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="selectedInsgZustand"
              :items="selectedInsgZustandItems"
              item-text="text"
              item-value="text"
              label="Bitte auswählen..."
              return-object
              single-line
              outlined
            ></v-select>
          </v-col>
          <v-col cols="3">
          Zustand. Kurz- bis mittelfristig stehen 
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="selectedArbeiten"
              :items="selectedArbeitenItems"
              item-text="text"
              item-value="text"
              label="Bitte auswählen..."
              return-object
              single-line
              outlined
            ></v-select>
          </v-col>
          <v-col cols="2">
          Renovierungsarbeiten an.
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider ></v-divider>
      <v-card-actions>
        <v-row align="center">
          <v-col cols="12">
            <center>
              <v-btn color="#feb901" @click="checkComplete()">Text erstellen</v-btn>
            </center>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-text v-if="text !== ''">
        
        <v-textarea
          name="input-7-1"
          outlined
          label="Generierter Text"
          auto-grow
          :value="text"
        ></v-textarea>
        <center>
          <v-btn color="#feb901" v-clipboard="text" @click="showCopied()">
           Copy!
          </v-btn>
        </center>
      </v-card-text>
     
      
    </v-card>
     <v-footer
      padless
      class="font-weight-medium"
    >
      <v-col
        class="text-center"
        cols="12"
      >
      Copyright 2020 — <a color="#feb901" @click.stop="openImprint = true">Impressum</a>
      </v-col>
    </v-footer>
    <v-row justify="center">
    
    <v-dialog
      v-model="openImprint"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Impressum
        </v-card-title>

        <v-card-text>
         
          <p>Angaben gemäß § 5 TMG:</p><p>Dejan Mühlhaus <br> 
          Reschop 8<br> 
          45525 Hattingen <br> 
          Deutschland<br> 
          </p><p> <strong>Vertreten durch: </strong><br>
          Dejan Mühlhaus<br>
          </p><p><strong>Kontakt:</strong> <br>
          Telefon: 0176/70656715<br>
          E-Mail: <a href='dejan.muehlhaus@gmail.com'>dejan.muehlhaus@gmail.com</a></p>


        </v-card-text>

        
      </v-card>
    </v-dialog>
  </v-row>
  </v-container>
</template>

<script>
import cities from './cities.js'
  export default {
    name: 'HelloWorld',

    data: () => ({
      openImprint: false,
      cityArray : [],
      additionalInfo: "",
      error: false,
      copied: false,
      text: '',
      city: "",
      enabledGuestWC: false,
      enabledBalkon: false,
      enabledPraktisch: false,
      enabledPlaetze: false,
      selectedEinleitung: {},
      selectedEinleitungItems: [
        { text: 'ein Haus' },
        { text: 'eine Wohnung' },
        { text: 'eine Doppelhaushälfte' },
        { text: 'ein Reihenmittelhaus' },
        { text: 'ein Reihenendhaus' },
      ],
      selectedHeizung: {},
      selectedHeizungItems: [
        { text: 'Gas-Zentralheizung' },
        { text: 'Gas-Etagenheizung' },
        { text: 'Öl-Heizung' },
        { text: 'Stromheizung' },
      ],
      selectedZustand: {},
      selectedZustandItems: [
        { text: 'einwandfreien' },
        { text: 'funktionsfähigen' },
      ],
      selectedFenster: {},
      selectedFensterItems: [
        { text: 'Kunststoffrahmen' },
        { text: 'Holzrahmen' },
      ],
      selectedGlas: {},
      selectedGlasItems: [
        { text: 'Doppelverglasung' },
        { text: 'Dreifachverglasung' },
        { text: 'Einfachverglasung' },
      ],
      selectedWC: {},
      selectedWCItems: [
        { text: 'Das Badezimmer ist in den letzten Jahren saniert worden und verfügt über moderne Armaturen. ' },
        { text: 'Das Badezimmer ist neutral gefliest und zeitgemäß ausgestattet. ' },
        { text: 'Das Badezimmer ist in den letzten Jahren nicht saniert worden und sollte mittelfristig erneuert werden. ' },
      ],
      selectedGuestWC: {},
      selectedGuestWCItems: [
        { text: 'Toilette und Waschbecken.' },
        { text: 'Dusche und Waschbecken.' },
      ],
      selectedPlaetze: {},
      selectedPlaetzeItems: [
        { text: 'ist ein PKW-Stellpaltz' },
        { text: 'sind zwei PKW-Stellplätze' },
        { text: 'sind mehrere PKW-Stellplätze' },
      ],
      selectedInsgZustand: {},
      selectedInsgZustandItems: [
        { text: 'gepflegten' },
        { text: 'eher schlechten' },
        { text: 'mittelmäßigen' },
      ],
      selectedArbeiten: {},
      selectedArbeitenItems: [
        { text: 'keine' },
        { text: 'nur kleinere' },
        { text: 'einige' },
      ],
    }),
    methods: {
      showCopied(){
        this.copied = true;
      },
      checkComplete(){
        console.log("check")
        console.log(this.selectedEinleitung)
        if(
          !this.selectedEinleitung.text ||
          !this.selectedHeizung.text  ||
          !this.selectedZustand.text||
          !this.selectedFenster.text ||
          !this.selectedGlas.text ||
          !this.selectedWC.text ||
          !this.selectedInsgZustand.text ||
          !this.selectedArbeiten.text ||
          (this.enabledGuestWC && !this.selectedGuestWC.text) ||
          (this.enabledPlaetze && !this.selectedPlaetze.text) ||
          this.city == ""
          ){
            console.log("error")
            this.error= true;
          }else{
            this.generateText();
          }
      },
      generateText(){
        this.text = "";

        this.text += "Bei der Immobilie handelt es sich um ";
        this.text += this.selectedEinleitung.text;
        this.text += " in ";
        this.text += this.city + ". ";

        this.text += "Der Wohnbereich wird über eine ";
        this.text += this.selectedHeizung.text;
        this.text += " mit Wärme versorgt, die Heizungsanlage wird in regelmäßigen Abständen gewartet und befindet sich im ";
        this.text += this.selectedZustand.text;
        this.text += " Zustand. ";

        this.text += "Bei den Fenstern handelt es sich um ";
        this.text += this.selectedFenster.text;
        this.text += " mit ";
        this.text += this.selectedGlas.text;
        this.text += ". "

        this.text += this.selectedWC.text;

        if(this.enabledGuestWC){
          this.text += "Zusätzlich gibt es ein Gäste-WC mit ";
          this.text += this.selectedGuestWC.text;
        }
        if(this.enabledBalkon){
          this.text += " Die Immobilie verfügt über einen Balkon mit ausreichender Sitzfläche.";
        }
        if(this.enabledPlaetze){
          this.text += " Zusätzlich ";
          this.text += this.selectedPlaetze.text;
          this.text += " vorhanden.";
        }
        if(this.enabledPraktisch){
          this.text += " Die Immobilie ist äußerst praktisch geschnitten, die Wohnraumaufteilung ist sinnvoll angeordnet und absolut zeitgemäß.";
        }

        if(this.additionalInfo !== ""){
          if(this.additionalInfo.charAt(this.additionalInfo.length-1) == "."){
            this.text += this.additionalInfo ;
          }else{
            this.text += this.additionalInfo + ".";
          }
        }
    
        this.text += " Insgesamt befindet sich die Immobilie in einem ";
        this.text += this.selectedInsgZustand.text;
        this.text += " Zustand. Kurz- bis mittelfristig stehen ";
        this.text += this.selectedArbeiten.text;
        this.text += " Renovierungsarbeiten an."
        this.$clipboard(this.text)
        this.copied = true;



        console.log(this.text)
      }
    },
    mounted: function(){
      this.cityArray = cities.returnCities();
    }
  }
</script>
