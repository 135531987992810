export default {
    returnCities(){
  
      var cities = [
        "Aachen",
        "Aach",
        "Aalen",
        "Abenberg",
        "Abensberg",
        "Achern",
        "Achim",
        "Adelsheim",
        "Adenau",
        "Adorf",
        "Ahaus",
        "Ahlen",
        "Ahrensburg",
        "Aichach",
        "Aichtal",
        "Aken",
        "Albstadt",
        "Alfeld",
        "Allendorf",
        "Allstedt",
        "Alpirsbach",
        "Alsdorf",
        "Alsfeld",
        "Alsleben",
        "Altdorf bei Nürnberg",
        "Altena",
        "Altenberg",
        "Altenburg",
        "Altenkirchen",
        "Altensteig",
        "Altentreptow",
        "Altlandsberg",
        "Altötting",
        "Alzenau",
        "Alzey",
        "Amberg",
        "Amorbach",
        "Amt Creuzburg",
        "Amöneburg",
        "An der Schmücke",
        "Andernach",
        "Angermünde",
        "Anklam",
        "Annaberg-Buchholz",
        "Annaburg",
        "Annweiler am Trifels",
        "Ansbach",
        "Apolda",
        "Arendsee",
        "Arneburg",
        "ArnisArnæs",
        "Arnsberg",
        "Arnstadt",
        "Arnstein",
        "Arnstein",
        "Artern",
        "Arzberg",
        "Aschaffenburg",
        "Aschersleben",
        "Asperg",
        "Attendorn",
        "Aub",
        "Aue-Bad Schlema",
        "Auerbach in der Oberpfalz",
        "Auerbach",
        "Augsburg",
        "Augustusburg",
        "Aulendorf",
        "Auma-Weidatal",
        "Aurich",
        "Aßlar",
        "Babenhausen",
        "Bacharach",
        "Backnang",
        "Bad Aibling",
        "Bad Arolsen",
        "Bad Belzig",
        "Bad Bentheim",
        "Bad Bergzabern",
        "Bad Berka",
        "Bad Berleburg",
        "Bad Berneck i.Fichtelgebirge",
        "Bad Bevensen",
        "Bad Bibra",
        "Bad Blankenburg",
        "Bad Bramstedt",
        "Bad Breisig",
        "Bad Brückenau",
        "Bad Buchau",
        "Bad Camberg",
        "Bad Doberan",
        "Bad Driburg",
        "Bad Düben",
        "Bad Dürkheim",
        "Bad Dürrenberg",
        "Bad Dürrheim",
        "Bad Elster",
        "Bad Ems",
        "Bad Fallingbostel",
        "Bad Frankenhausen",
        "Bad Freienwalde",
        "Bad Friedrichshall",
        "Bad Gandersheim",
        "Bad Gottleuba-Berggießhübel",
        "Bad Griesbach i.Rottal",
        "Bad Harzburg",
        "Bad Herrenalb",
        "Bad Hersfeld",
        "Bad Homburg v. d. Höhe",
        "Bad Honnef",
        "Bad Hönningen",
        "Bad Iburg",
        "Bad Karlshafen",
        "Bad Kissingen",
        "Bad Kreuznach",
        "Bad Krozingen",
        "Bad Königshofen",
        "Bad König",
        "Bad Köstritz",
        "Bad Kötzting",
        "Bad Laasphe",
        "Bad Langensalza",
        "Bad Lauchstädt",
        "Bad Lausick",
        "Bad Lauterberg",
        "Bad Liebenstein",
        "Bad Liebenwerda",
        "Bad Liebenzell",
        "Bad Lippspringe",
        "Bad Lobenstein",
        "Bad Marienberg",
        "Bad Mergentheim",
        "Bad Muskau",
        "Bad Münder",
        "Bad Münstereifel",
        "Bad Nauheim",
        "Bad Nenndorf",
        "Bad Neuenahr-Ahrweiler",
        "Bad Neustadt an der Saale",
        "Bad Oeynhausen",
        "Bad Oldesloe",
        "Bad Orb",
        "Bad Pyrmont",
        "Bad Rappenau",
        "Bad Reichenhall",
        "Bad Rodach",
        "Bad Sachsa",
        "Bad Salzdetfurth",
        "Bad Salzuflen",
        "Bad Salzungen",
        "Bad Saulgau",
        "Bad Schandau",
        "Bad Schmiedeberg",
        "Bad Schussenried",
        "Bad Schwalbach",
        "Bad Schwartau",
        "Bad Segeberg",
        "Bad Sobernheim",
        "Bad Soden-Salmünster",
        "Bad Soden",
        "Bad Sooden-Allendorf",
        "Bad Staffelstein",
        "Bad Sulza",
        "Bad Säckingen",
        "Bad Sülze",
        "Bad Teinach-Zavelstein",
        "Bad Tennstedt",
        "Bad Tölz",
        "Bad Urach",
        "Bad Vilbel",
        "Bad Waldsee",
        "Bad Wildbad",
        "Bad Wildungen",
        "Bad Wilsnack",
        "Bad Wimpfen",
        "Bad Windsheim",
        "Bad Wurzach",
        "Bad Wörishofen",
        "Bad Wünnenberg",
        "Baden-Baden",
        "Baesweiler",
        "Baiersdorf",
        "Balingen",
        "Ballenstedt",
        "Balve",
        "Bamberg",
        "Barby",
        "Bargteheide",
        "Barmstedt",
        "Barntrup",
        "Barsinghausen",
        "Barth",
        "Baruth/Mark",
        "Bassum",
        "Battenberg",
        "Baumholder",
        "Baunach",
        "Baunatal",
        "Bautzen",
        "Bayreuth",
        "Bebra",
        "Beckum",
        "Bedburg",
        "Beelitz",
        "Beeskow",
        "Beilngries",
        "Beilstein",
        "Belgern-Schildau",
        "Bendorf",
        "Bensheim",
        "Berching",
        "Berga",
        "Bergen auf Rügen",
        "Bergen",
        "Bergheim",
        "Bergisch Gladbach",
        "Bergkamen",
        "Bergneustadt",
        "Berlin",
        "Bernau bei Berlin",
        "Bernburg",
        "Bernkastel-Kues",
        "Bernsdorf",
        "Bernstadt auf dem Eigen",
        "Bersenbrück",
        "Besigheim",
        "Betzdorf",
        "Betzenstein",
        "Beverungen",
        "Bexbach",
        "Biberach an der Riss",
        "Biedenkopf",
        "Bielefeld",
        "Biesenthal",
        "Bietigheim-Bissingen",
        "Billerbeck",
        "Bingen am Rhein",
        "Birkenfeld",
        "Bischofsheim i.d.Rhön",
        "Bischofswerda",
        "Bismark",
        "Bitburg",
        "Bitterfeld-Wolfen",
        "Blankenburg",
        "Blankenhain",
        "Blaubeuren",
        "Blaustein",
        "Bleckede",
        "Bleicherode",
        "Blieskastel",
        "Blomberg",
        "Blumberg",
        "Bobingen",
        "Bocholt",
        "Bochum",
        "Bockenem",
        "Bodenwerder",
        "Bogen",
        "Boizenburg",
        "Bonndorf im Schwarzwald",
        "Bonn",
        "Bopfingen",
        "Boppard",
        "Borgentreich",
        "Borgholzhausen",
        "Borken",
        "Borken",
        "Borkum",
        "Borna",
        "Bornheim",
        "Bottrop",
        "Boxberg",
        "Brackenheim",
        "Brakel",
        "Brake",
        "Bramsche",
        "Brand-Erbisdorf",
        "Brandenburg an der Havel",
        "Brandis",
        "Braubach",
        "Braunfels",
        "Braunlage",
        "Braunsbedra",
        "Breckerfeld",
        "BredstedtBräist / Bredsted",
        "Breisach",
        "Bremen",
        "Bremerhaven",
        "Bremervörde",
        "Bretten",
        "Breuberg",
        "Brilon",
        "Brotterode-Trusetal",
        "Bruchköbel",
        "Bruchsal",
        "Brunsbüttel",
        "Brunswick",
        "Bräunlingen",
        "Brück",
        "Brüel",
        "Brühl",
        "Brüssow",
        "Buchen",
        "Buchholz in der Nordheide",
        "Buchloe",
        "Buckow",
        "Burg Stargard",
        "Burgau",
        "Burgbernheim",
        "Burgdorf",
        "Burghausen",
        "Burgkunstadt",
        "Burglengenfeld",
        "Burgstädt",
        "Burgwedel",
        "Burg",
        "Burladingen",
        "Burscheid",
        "Butzbach",
        "Bärnau",
        "Böblingen",
        "Böhlen",
        "Bönnigheim",
        "Bückeburg",
        "Büdelsdorf",
        "Büdingen",
        "Bühl",
        "Bünde",
        "Büren",
        "Bürgel",
        "Bürstadt",
        "Bützow",
        "Calau/Kalawa",
        "Calbe",
        "Calw",
        "Castrop-Rauxel",
        "Celle",
        "Cham",
        "Chemnitz",
        "Clausthal-Zellerfeld",
        "Cleves",
        "Clingen",
        "Cloppenburg",
        "Coburg",
        "Cochem",
        "Coesfeld",
        "Colditz",
        "Cologne",
        "Constance",
        "Coswig",
        "Coswig",
        "Cottbus/Chóśebuz",
        "Crailsheim",
        "Creglingen",
        "Creußen",
        "Crimmitschau",
        "Crivitz",
        "Cuxhaven",
        "Daaden",
        "Dachau, Bavaria",
        "Dahlen",
        "Dahme",
        "Dahn",
        "Damme",
        "Dannenberg",
        "Dargun",
        "Darmstadt",
        "Dassel",
        "Dassow",
        "Datteln",
        "Daun",
        "Deggendorf",
        "Deidesheim",
        "Delbrück",
        "Delitzsch",
        "Delmenhorst",
        "Demmin",
        "Dessau-Roßlau",
        "Detmold",
        "Dettelbach",
        "Dieburg",
        "Diemelstadt",
        "Diepholz",
        "Dierdorf",
        "Dietenheim",
        "Dietfurt a.d. Altmühl",
        "Dietzenbach",
        "Diez, Germany",
        "Dillenburg",
        "Dillingen an der Donau",
        "Dillingen",
        "Dingelstädt",
        "Dingolfing",
        "Dinkelsbühl",
        "Dinklage",
        "Dinslaken",
        "Dippoldiswalde",
        "Dissen, Lower Saxony",
        "Ditzingen",
        "Doberlug-Kirchhain",
        "Dohna",
        "Dommitzsch",
        "Donaueschingen",
        "Donauwörth",
        "Donzdorf",
        "Dorfen",
        "Dormagen",
        "Dornburg-Camburg",
        "Dornhan",
        "Dornstetten",
        "Dorsten",
        "Dortmund",
        "Dransfeld",
        "Drebkau/Drjowk",
        "Dreieich",
        "Drensteinfurt",
        "Dresden",
        "Drolshagen",
        "Duderstadt",
        "Duisburg",
        "Döbeln",
        "Döbern",
        "Dömitz",
        "Dülmen",
        "Düren",
        "Düsseldorf",
        "Ebeleben",
        "Eberbach",
        "Ebermannstadt",
        "Ebern",
        "Ebersbach an der Fils",
        "Ebersbach-Neugersdorf",
        "Ebersberg",
        "Eberswalde",
        "Eckartsberga",
        "EckernfördeEckernföör, Egernførde",
        "Edenkoben",
        "Egeln",
        "Eggenfelden",
        "Eggesin",
        "Ehingen",
        "Ehrenfriedersdorf",
        "Eibelstadt",
        "Eibenstock",
        "Eichstätt",
        "Eilenburg",
        "Einbeck",
        "Eisenach",
        "Eisenberg",
        "Eisenberg",
        "Eisenhüttenstadt",
        "Eisfeld",
        "Eisleben",
        "Eislingen",
        "Ellingen",
        "Ellrich",
        "Ellwangen",
        "Elmshorn",
        "Elsdorf",
        "Elsfleth",
        "Elsterberg",
        "Elsterwerda",
        "Elstra",
        "Elterlein",
        "Eltmann",
        "Eltville",
        "Elzach",
        "Elze",
        "Emden",
        "Emmelshausen",
        "Emmendingen",
        "Emmerich",
        "Emsdetten",
        "Endingen",
        "Engen",
        "Enger",
        "Ennepetal",
        "Ennigerloh",
        "Eppelheim",
        "Eppingen",
        "Eppstein",
        "Erbach",
        "Erbach",
        "Erbendorf",
        "Erding",
        "Erftstadt",
        "Erfurt",
        "Erkelenz",
        "Erkner",
        "Erkrath",
        "Erlangen",
        "Erlenbach a.Main",
        "Erlensee",
        "Erwitte",
        "Eschborn",
        "Eschenbach in der Oberpfalz",
        "Eschershausen",
        "Eschwege",
        "Eschweiler",
        "Esens",
        "Espelkamp",
        "Essen",
        "Esslingen am Neckar",
        "Ettenheim",
        "Ettlingen",
        "Euskirchen",
        "Eutin",
        "Falkenberg/Elster",
        "Falkensee",
        "Falkenstein/Harz",
        "Falkenstein",
        "Fehmarn",
        "Fellbach",
        "Felsberg",
        "Feuchtwangen",
        "Filderstadt",
        "Finsterwalde",
        "Fladungen",
        "Flensburg",
        "Florstadt",
        "Flöha",
        "Flörsheim am Main",
        "Forchheim",
        "Forchtenberg",
        "Forst (Lausitz)",
        "Frankenau",
        "Frankenberg",
        "Frankenberg",
        "Frankenthal (Pfalz)",
        "Frankfurt am Main",
        "Frankfurt",
        "Franzburg",
        "Frauenstein",
        "Frechen",
        "Freiberg am Neckar",
        "Freiberg",
        "Freiburg im Breisgau",
        "Freilassing",
        "Freinsheim",
        "Freising",
        "Freital",
        "Freren",
        "Freudenberg",
        "Freudenberg",
        "Freudenstadt",
        "Freyburg",
        "Freystadt",
        "Freyung",
        "Fridingen",
        "Friedberg",
        "Friedberg",
        "Friedland",
        "Friedland",
        "Friedrichroda",
        "Friedrichsdorf",
        "Friedrichshafen",
        "FriedrichstadtFrederiksstad",
        "Friedrichsthal",
        "Friesack",
        "Friesoythe",
        "Fritzlar",
        "Frohburg",
        "Fröndenberg",
        "Fulda",
        "Furth im Wald",
        "Furtwangen im Schwarzwald",
        "Fürstenau",
        "Fürstenberg",
        "Fürstenfeldbruck",
        "Fürstenwalde/Spree",
        "Fürth",
        "Füssen",
        "Gadebusch",
        "Gaggenau",
        "Gaildorf",
        "Gammertingen",
        "Garbsen",
        "Garching bei München",
        "Gardelegen",
        "Garding",
        "Gartz",
        "Garz",
        "Gau-Algesheim",
        "Gebesee",
        "Gedern",
        "Geesthacht",
        "Geestland",
        "Gefell",
        "Gefrees",
        "Gehrden",
        "Geilenkirchen",
        "Geisa",
        "Geiselhöring",
        "Geisenfeld",
        "Geisenheim",
        "Geisingen",
        "Geislingen an der Steige",
        "Geislingen",
        "Geithain",
        "Geldern",
        "Gelnhausen",
        "Gelsenkirchen",
        "Gemünden am Main",
        "Gemünden",
        "Gengenbach",
        "Genthin",
        "Georgsmarienhütte",
        "Gerabronn",
        "Gera",
        "Gerbstedt",
        "Geretsried",
        "Geringswalde",
        "Gerlingen",
        "Germering",
        "Germersheim",
        "Gernsbach",
        "Gernsheim",
        "Gerolstein",
        "Gerolzhofen",
        "Gersfeld",
        "Gersthofen",
        "Gescher",
        "Geseke",
        "Gevelsberg",
        "Geyer",
        "Giengen",
        "Giessen",
        "Gifhorn",
        "Ginsheim-Gustavsburg",
        "Gladbeck",
        "Gladenbach",
        "Glashütte",
        "Glauchau",
        "Glinde",
        "GlücksburgLyksborg",
        "Glückstadt",
        "Gnoien",
        "Goch",
        "Goldberg",
        "Goldkronach",
        "Golßen",
        "Gommern",
        "Goslar",
        "Gotha",
        "Grabow",
        "Grafenau",
        "Grafenwöhr",
        "Grafing bei München",
        "Gransee",
        "Grebenau",
        "Grebenstein",
        "Greding",
        "Greifswald",
        "Greiz",
        "Greußen",
        "Grevenbroich",
        "Greven",
        "Grevesmühlen",
        "Griesheim, Hesse",
        "Grimma",
        "Grimmen",
        "Groitzsch",
        "Gronau",
        "Gronau",
        "Groß-Bieberau",
        "Groß-Gerau",
        "Groß-Umstadt",
        "Großalmerode",
        "Großbottwar",
        "Großbreitenbach",
        "Großenehrich",
        "Großenhain",
        "Großräschen",
        "Großröhrsdorf",
        "Großschirma",
        "Gräfenberg, Bavaria",
        "Gräfenhainichen",
        "Gräfenthal",
        "Gröditz",
        "Gröningen",
        "Grünberg",
        "Grünhain-Beierfeld",
        "Grünsfeld",
        "Grünstadt",
        "Guben",
        "Gudensberg",
        "Gummersbach",
        "Gundelfingen an der Donau",
        "Gundelsheim",
        "Gunzenhausen",
        "Göppingen",
        "Görlitz",
        "Göttingen",
        "Gößnitz",
        "Güglingen",
        "Günzburg",
        "Güsten",
        "Güstrow",
        "Gütersloh",
        "Gützkow",
        "Haan",
        "Hachenburg",
        "Hadamar",
        "Hagenbach",
        "Hagenow",
        "Hagen",
        "Haigerloch",
        "Haiger",
        "Hainichen",
        "Haiterbach",
        "Halberstadt",
        "Haldensleben",
        "Halle (Saale)",
        "Hallenberg",
        "Halle",
        "Hallstadt",
        "Haltern am See",
        "Halver",
        "Hamburg",
        "Hamelin",
        "Hammelburg",
        "Hamminkeln",
        "Hamm",
        "Hanau",
        "Hann. Münden",
        "Hanover",
        "Hanseatic City of Buxtehude",
        "Hanseatic City of Korbach",
        "Hanseatic City of Stade",
        "Harburg",
        "Hardegsen",
        "Haren, Germany",
        "Harsewinkel",
        "Hartenstein",
        "Hartha",
        "Harzgerode",
        "Haselünne",
        "Haslach im Kinzigtal",
        "Hattersheim am Main",
        "Hattingen",
        "Hatzfeld",
        "Hausach",
        "Hauzenberg",
        "Havelberg",
        "Havelsee",
        "Hayingen",
        "Haßfurt",
        "Hechingen",
        "Hecklingen",
        "Heideck",
        "Heidelberg",
        "Heidenau",
        "Heidenheim an der Brenz",
        "Heide",
        "Heilbad Heiligenstadt",
        "Heilbronn",
        "Heiligenhafen",
        "Heiligenhaus",
        "Heilsbronn",
        "Heimbach",
        "Heimsheim",
        "Heinsberg",
        "Heitersheim",
        "Heldburg",
        "Helmbrechts",
        "Helmstedt",
        "Hemau",
        "Hemer",
        "Hemmingen",
        "Hemmoor",
        "Hemsbach",
        "Hennef",
        "Hennigsdorf",
        "Heppenheim",
        "Herbolzheim",
        "Herborn, Hesse",
        "Herbrechtingen",
        "Herbstein",
        "Herdecke",
        "Herdorf",
        "Herford",
        "Heringen",
        "Heringen",
        "Hermeskeil",
        "Hermsdorf",
        "Herne",
        "Herrenberg",
        "Herrieden",
        "Herrnhut",
        "Hersbruck",
        "Herten",
        "Herzberg am Harz",
        "Herzberg",
        "Herzogenaurach",
        "Herzogenrath",
        "Hessisch Lichtenau",
        "Hessisch Oldendorf",
        "Hettingen",
        "Hettstedt",
        "Heubach",
        "Heusenstamm",
        "Hilchenbach",
        "Hildburghausen",
        "Hilden",
        "Hildesheim",
        "Hillesheim",
        "Hilpoltstein",
        "Hirschau",
        "Hirschberg",
        "Hirschhorn",
        "Hitzacker",
        "Hochheim am Main",
        "Hockenheim",
        "Hofgeismar",
        "Hofheim, Bavaria",
        "Hofheim, Hesse",
        "Hof",
        "Hohen Neuendorf",
        "Hohenberg a.d.Eger",
        "Hohenleuben",
        "Hohenmölsen",
        "Hohenstein-Ernstthal",
        "Hohnstein",
        "Hollfeld",
        "Holzgerlingen",
        "Holzminden",
        "Homberg",
        "Homberg",
        "Homburg",
        "Horb am Neckar",
        "Horn-Bad Meinberg",
        "Hornbach, Germany",
        "Hornberg",
        "Horstmar",
        "Hoya",
        "Hoyerswerda",
        "Hungen",
        "Husum",
        "Höchstadt",
        "Höchstädt an der Donau",
        "Höhr-Grenzhausen",
        "Hörstel",
        "Höxter",
        "Hückelhoven",
        "Hückeswagen",
        "Hüfingen",
        "Hünfeld",
        "Hürth",
        "Ibbenbüren",
        "Ichenhausen",
        "Idar-Oberstein",
        "Idstein",
        "Illertissen",
        "Ilmenau",
        "Ilsenburg",
        "Ilshofen",
        "Immenhausen",
        "Immenstadt",
        "Ingelfingen",
        "Ingelheim am Rhein",
        "Ingolstadt",
        "Iphofen",
        "Iserlohn",
        "Isny im Allgäu",
        "Isselburg",
        "Itzehoe",
        "Jarmen",
        "Jena",
        "Jerichow",
        "Jessen",
        "Jever",
        "Joachimsthal",
        "Johanngeorgenstadt",
        "Jöhstadt",
        "Jüchen",
        "Jülich",
        "Jüterbog",
        "Kaarst",
        "Kahla",
        "Kaisersesch",
        "Kaiserslautern",
        "Kalbe, Saxony-Anhalt",
        "Kalkar",
        "Kaltenkirchen",
        "Kaltennordheim",
        "Kamenz",
        "Kamen",
        "Kamp-Lintfort",
        "Kandel",
        "Kandern",
        "KappelnKappel",
        "Karben",
        "Karlsruhe",
        "Karlstadt am Main",
        "Kassel",
        "Kastellaun",
        "Katzenelnbogen",
        "Kaub",
        "Kaufbeuren",
        "Kehl",
        "Kelbra",
        "Kelheim",
        "Kelkheim",
        "Kellinghusen",
        "Kelsterbach",
        "Kemberg",
        "Kemnath",
        "Kempen",
        "Kempten",
        "Kenzingen",
        "Kerpen",
        "Ketzin",
        "Kevelaer",
        "Kiel",
        "Kierspe",
        "Kirchberg an der Jagst",
        "Kirchberg",
        "Kirchberg",
        "Kirchenlamitz",
        "Kirchen",
        "Kirchhain",
        "Kirchheim unter Teck",
        "Kirchheimbolanden",
        "Kirn",
        "Kirtorf",
        "Kitzingen",
        "Kitzscher",
        "Klingenberg am Main",
        "Klingenthal",
        "Klötze",
        "Klütz",
        "Knittlingen",
        "Koblenz",
        "Kolbermoor",
        "Konz",
        "Korntal-Münchingen",
        "Kornwestheim",
        "Korschenbroich",
        "Kraichtal",
        "Krakow am See",
        "Kranichfeld",
        "Krautheim",
        "Krefeld",
        "Kremmen",
        "Krempe",
        "Kreuztal",
        "Kronach",
        "Kronberg im Taunus",
        "Kroppenstedt",
        "Krumbach",
        "Kröpelin",
        "Kulmbach",
        "Kupferberg",
        "Kuppenheim",
        "Kusel",
        "Kyllburg",
        "Kyritz",
        "Kölleda",
        "Königs Wusterhausen",
        "Königsberg in Bayern",
        "Königsbrunn",
        "Königsbrück",
        "Königsee",
        "Königslutter",
        "Königstein im Taunus",
        "Königstein",
        "Königswinter",
        "Könnern",
        "Köthen (Anhalt)",
        "Kühlungsborn",
        "Külsheim",
        "Künzelsau",
        "Laage",
        "Laatzen",
        "Ladenburg",
        "Lage",
        "Lahnstein",
        "Lahr",
        "Laichingen",
        "Lambrecht, Rhineland-Palatinate",
        "Lampertheim",
        "Landau an der Isar",
        "Landau",
        "Landsberg am Lech",
        "Landsberg",
        "Landshut",
        "Landstuhl",
        "Langelsheim",
        "Langen, Hesse",
        "Langenau",
        "Langenburg",
        "Langenfeld",
        "Langenhagen",
        "Langenselbold",
        "Langenzenn",
        "Lassan",
        "Laubach",
        "Laucha an der Unstrut",
        "Lauchhammer",
        "Lauchheim",
        "Lauda-Königshofen",
        "Lauenburg",
        "Lauf a.d.Pegnitz",
        "Laufenburg",
        "Laufen",
        "Lauffen",
        "Lauingen",
        "Laupheim",
        "Lauscha",
        "Lauta",
        "Lauter-Bernsbach",
        "Lauterbach",
        "Lauterecken",
        "Lauterstein",
        "Lebach",
        "Lebus",
        "Leer",
        "Lehesten",
        "Lehrte",
        "Leichlingen",
        "Leimen",
        "Leinefelde-Worbis",
        "Leinfelden-Echterdingen",
        "Leingarten",
        "Leipheim",
        "Leipzig",
        "Leisnig",
        "Lemgo",
        "Lengenfeld",
        "Lengerich",
        "Lennestadt",
        "Lenzen",
        "Leonberg",
        "Leuna",
        "Leun",
        "Leutenberg",
        "Leutershausen",
        "Leutkirch im Allgäu",
        "Leverkusen",
        "Lichtenau",
        "Lichtenau",
        "Lichtenberg",
        "Lichtenfels",
        "Lichtenfels",
        "Lichtenstein",
        "Lich",
        "Liebenau",
        "Liebenwalde",
        "Lieberose",
        "Liebstadt",
        "Limbach-Oberfrohna",
        "Limburg an der Lahn",
        "Lindau",
        "Lindenberg im Allgäu",
        "Lindenfels",
        "Linden",
        "Lindow",
        "Lingen",
        "Linnich",
        "Linz am Rhein",
        "Lippstadt",
        "Lohmar",
        "Lohne (Oldenburg)",
        "Lohr a. Main",
        "Loitz",
        "Lollar",
        "Lommatzsch",
        "Lorch",
        "Lorch",
        "Lorsch",
        "Luckau",
        "Lucka",
        "Luckenwalde",
        "Ludwigsburg",
        "Ludwigsfelde",
        "Ludwigshafen am Rhein",
        "Ludwigslust",
        "Ludwigsstadt",
        "Lugau",
        "Lunzenau",
        "Lychen",
        "Löbau",
        "Löffingen",
        "Löhne",
        "Löningen",
        "Lörrach",
        "Löwenstein",
        "Lößnitz",
        "Lübbecke",
        "Lübben/Lubin",
        "Lübbenau/Lubnjow",
        "Lübeck",
        "Lübtheen",
        "Lübz",
        "Lüchow",
        "Lüdenscheid",
        "Lüdinghausen",
        "Lügde",
        "Lüneburg",
        "Lünen",
        "Lütjenburg",
        "Lützen",
        "Magdala",
        "Magdeburg",
        "Mahlberg",
        "Mainbernheim",
        "Mainburg",
        "Maintal",
        "Mainz",
        "Malchin",
        "Malchow",
        "Manderscheid",
        "Mannheim",
        "Mansfeld",
        "Marbach am Neckar",
        "Marburg",
        "Marienberg",
        "Marienmünster",
        "Markdorf",
        "Markgröningen",
        "Markkleeberg",
        "Markneukirchen",
        "Markranstädt",
        "Marktbreit",
        "Marktheidenfeld",
        "Marktleuthen",
        "Marktoberdorf",
        "Marktredwitz",
        "Marktsteft",
        "Marlow",
        "Marl",
        "Marne",
        "Marsberg",
        "Maulbronn",
        "Maxhütte-Haidhof",
        "Mayen",
        "Mechernich",
        "Meckenheim",
        "Medebach",
        "Meerane",
        "Meerbusch",
        "Meersburg",
        "Meinerzhagen",
        "Meiningen",
        "Meisenheim",
        "Meissen",
        "Meldorf",
        "Melle",
        "Mellrichstadt",
        "Melsungen",
        "Memmingen",
        "Menden",
        "Mendig",
        "Mengen",
        "Meppen",
        "Merkendorf",
        "Merseburg",
        "Merzig",
        "Meschede",
        "Mettmann",
        "Metzingen",
        "Meuselwitz",
        "Meyenburg",
        "Meßkirch",
        "Meßstetten",
        "Michelstadt",
        "Miesbach",
        "Miltenberg",
        "Mindelheim",
        "Minden",
        "Mirow",
        "Mittenwalde",
        "Mitterteich",
        "Mittweida",
        "Moers",
        "Monheim am Rhein",
        "Monheim",
        "Monschau",
        "Montabaur",
        "Moosburg an der Isar",
        "Moringen",
        "Mosbach",
        "Munderkingen",
        "Munich",
        "Munster",
        "Murrhardt",
        "Märkisch Buchholz",
        "Möckern",
        "Möckmühl",
        "Mölln",
        "Mönchengladbach",
        "Mörfelden-Walldorf",
        "Mössingen",
        "Mücheln",
        "Mügeln",
        "Mühlacker",
        "Mühlberg",
        "Mühldorf am Inn",
        "Mühlhausen",
        "Mühlheim am Main",
        "Mühlheim an der Donau",
        "Mülheim an der Ruhr",
        "Mülheim-Kärlich",
        "Müllheim",
        "Müllrose",
        "Münchberg",
        "Müncheberg",
        "Münchenbernsdorf",
        "Münnerstadt",
        "Münsingen",
        "Münstermaifeld",
        "Münster",
        "Münzenberg",
        "Nabburg",
        "Nagold",
        "Naila",
        "Nassau",
        "Nastätten",
        "Nauen",
        "Naumburg",
        "Naumburg",
        "Naunhof",
        "Nebra",
        "Neckarbischofsheim",
        "Neckargemünd",
        "Neckarsteinach",
        "Neckarsulm",
        "Neresheim",
        "Netphen",
        "Nettetal",
        "Netzschkau",
        "Neu-Anspach",
        "Neu-Isenburg",
        "Neu-Ulm",
        "Neubrandenburg",
        "Neubukow",
        "Neubulach",
        "Neuburg a.d. Donau",
        "Neudenau",
        "Neuenburg am Rhein",
        "Neuenbürg",
        "Neuenhaus",
        "Neuenrade",
        "Neuenstadt am Kocher",
        "Neuenstein",
        "Neuerburg",
        "Neuffen",
        "Neuhaus am Rennweg",
        "Neukalen",
        "Neukirchen-Vluyn",
        "Neukirchen",
        "Neukloster",
        "Neumarkt in der Oberpfalz",
        "Neumarkt-Sankt Veit",
        "Neumark",
        "Neumünster",
        "Neunburg vorm Wald",
        "Neunkirchen",
        "Neuruppin",
        "Neusalza-Spremberg",
        "Neuss",
        "Neustadt a.d.Donau",
        "Neustadt a.d.Waldnaab",
        "Neustadt am Kulm",
        "Neustadt am Rübenberge",
        "Neustadt an der Aisch",
        "Neustadt an der Orla",
        "Neustadt an der Weinstraße",
        "Neustadt b.Coburg",
        "Neustadt in Holstein",
        "Neustadt in Sachsen",
        "Neustadt-Glewe",
        "Neustadt",
        "Neustadt",
        "Neustrelitz",
        "Neusäß",
        "Neutraubling",
        "Neuwied",
        "Neuötting",
        "Niddatal",
        "Nidda",
        "Nidderau",
        "Nideggen",
        "NiebüllNaibel / Nibøl",
        "Niedenstein",
        "Nieder-Olm",
        "Niederkassel",
        "Niedernhall",
        "Niederstetten",
        "Niederstotzingen",
        "Nieheim",
        "Niemegk",
        "Nienburg",
        "Nienburg",
        "Nierstein",
        "Niesky",
        "Nittenau",
        "Nordenham",
        "Norden",
        "Norderney",
        "Norderstedt",
        "Nordhausen, Thuringia",
        "Nordhorn",
        "Northeim",
        "Nortorf",
        "Nossen",
        "Nottertal-Heilinger Höhen",
        "Nuremberg",
        "Nördlingen",
        "Nürtingen",
        "Ober-Ramstadt",
        "Oberasbach",
        "Oberharz am Brocken",
        "Oberhausen",
        "Oberhof",
        "Oberkirch",
        "Oberkochen",
        "Oberlungwitz",
        "Obermoschel",
        "Obernburg a.Main",
        "Oberndorf am Neckar",
        "Obernkirchen",
        "Oberriexingen",
        "Obertshausen",
        "Oberursel (Taunus)",
        "Oberviechtach",
        "Oberwesel",
        "Oberwiesenthal",
        "Oberzent",
        "Ochsenfurt",
        "Ochsenhausen",
        "Ochtrup",
        "Oderberg",
        "Oebisfelde-Weferlingen",
        "Oederan",
        "Oelde",
        "Oelsnitz",
        "Oelsnitz",
        "Oer-Erkenschwick",
        "Oerlinghausen",
        "Oestrich-Winkel",
        "Oettingen in Bayern",
        "Offenbach am Main",
        "Offenburg",
        "Ohrdruf, Thuringia",
        "Olbernhau",
        "Olching",
        "Oldenburg in Holstein",
        "Oldenburg",
        "Olfen",
        "Olpe",
        "Olsberg",
        "Oppenau",
        "Oppenheim",
        "Oranienbaum-Wörlitz",
        "Oranienburg",
        "Orlamünde",
        "Ornbau",
        "Ortenberg",
        "Ortrand",
        "Oschatz",
        "Oschersleben",
        "Osnabrück",
        "Osterburg",
        "Osterburken",
        "Osterfeld",
        "Osterhofen",
        "Osterholz-Scharmbeck",
        "Osterode am Harz",
        "Osterwieck",
        "Ostfildern",
        "Ostheim",
        "Osthofen",
        "Ostritz",
        "Otterberg",
        "Otterndorf",
        "Ottweiler",
        "Overath",
        "Owen",
        "Paderborn",
        "Papenburg",
        "Pappenheim",
        "Parchim",
        "Parsberg",
        "Pasewalk",
        "Passau",
        "Pattensen",
        "Pausa-Mühltroff",
        "Pegau",
        "Pegnitz",
        "Peine",
        "Peitz/Picnjo",
        "Penig",
        "Penkun",
        "Penzberg",
        "Penzlin",
        "Perleberg",
        "Petershagen",
        "Pfaffenhofen a.d.Ilm",
        "Pfarrkirchen",
        "Pforzheim",
        "Pfreimd",
        "Pfullendorf",
        "Pfullingen",
        "Pfungstadt",
        "Philippsburg",
        "Pinneberg",
        "Pirmasens",
        "Pirna",
        "Plattling",
        "Plau am See",
        "Plauen",
        "Plaue",
        "Plettenberg",
        "Pleystein",
        "Plochingen",
        "Plön",
        "Pockau-Lengefeld",
        "Pocking",
        "Pohlheim",
        "Polch",
        "Porta Westfalica",
        "Potsdam",
        "Pottenstein",
        "Preetz",
        "Premnitz",
        "Prenzlau",
        "Pressath",
        "Preußisch Oldendorf",
        "Prichsenstadt",
        "Pritzwalk",
        "Prüm",
        "Puchheim",
        "Pulheim",
        "Pulsnitz",
        "Putbus",
        "Putlitz",
        "Pößneck",
        "Püttlingen",
        "Quakenbrück",
        "Quedlinburg",
        "Querfurt",
        "Quickborn",
        "Rabenau",
        "Radeberg",
        "Radebeul",
        "Radeburg",
        "Radevormwald",
        "Radolfzell",
        "Raguhn-Jeßnitz",
        "Rahden",
        "Rain",
        "Ramstein-Miesenbach",
        "Ranis",
        "Ransbach-Baumbach",
        "Rastatt",
        "Rastenberg",
        "Rathenow",
        "Ratingen",
        "Ratzeburg",
        "Rauenberg",
        "Raunheim",
        "Rauschenberg",
        "Ravensburg",
        "Ravenstein",
        "Recklinghausen",
        "Rees",
        "Regensburg",
        "Regen",
        "Regis-Breitingen",
        "Rehau",
        "Rehburg-Loccum",
        "Rehna",
        "Reichelsheim",
        "Reichenbach im Vogtland",
        "Reichenbach",
        "Reinbek",
        "Reinfeld",
        "Reinheim",
        "Remagen",
        "Remscheid",
        "Remseck",
        "Renchen",
        "Rendsburg",
        "Rennerod",
        "Renningen",
        "Rerik",
        "Rethem",
        "Reutlingen",
        "Rheda-Wiedenbrück",
        "Rhede",
        "Rheinau",
        "Rheinbach",
        "Rheinberg",
        "Rheinböllen",
        "Rheine",
        "Rheinfelden",
        "Rheinsberg",
        "Rheinstetten",
        "Rhens",
        "Rhinow",
        "Ribnitz-Damgarten",
        "Richtenberg",
        "Riedenburg",
        "Riedlingen",
        "Riedstadt",
        "Rieneck",
        "Riesa",
        "Rietberg",
        "Rinteln",
        "Rochlitz",
        "Rockenhausen",
        "Rodalben",
        "Rodenberg",
        "Rodewisch",
        "Rodgau",
        "Roding",
        "Romrod",
        "Ronneburg",
        "Ronnenberg",
        "Rosbach vor der Höhe",
        "Rosenfeld",
        "Rosenheim",
        "Rosenthal",
        "Rostock",
        "Rotenburg a. d.  Fulda",
        "Rotenburg an der Wümme",
        "Rothenburg ob der Tauber",
        "Rothenburg",
        "Rothenfels",
        "Roth",
        "Rottenburg a.d.Laaber",
        "Rottenburg am Neckar",
        "Rottweil",
        "Roßleben-Wiehe",
        "Roßwein",
        "Rudolstadt",
        "Ruhland",
        "Ruhla",
        "Runkel",
        "Rutesheim",
        "Röbel",
        "Rödental",
        "Rödermark",
        "Römhild",
        "Rösrath",
        "Rötha",
        "Röthenbach an der Pegnitz",
        "Röttingen",
        "Rötz",
        "Rüdesheim",
        "Rüsselsheim am Main",
        "Rüthen",
        "Saalburg-Ebersdorf",
        "Saalfeld",
        "Saarbrücken",
        "Saarburg",
        "Saarlouis",
        "Sachsenhagen",
        "Sachsenheim",
        "Salzgitter",
        "Salzkotten",
        "Salzwedel",
        "Sandau",
        "Sandersdorf-Brehna",
        "Sangerhausen",
        "Sankt Augustin",
        "Sankt Blasien",
        "Sankt Georgen im Schwarzwald",
        "Sankt Goarshausen",
        "Sankt Goar",
        "Sankt Ingbert",
        "Sankt Wendel",
        "Sarstedt",
        "Sassenberg",
        "Sassnitz",
        "Sayda, Saxony",
        "Schalkau",
        "Schauenstein",
        "Scheer",
        "Scheibenberg",
        "Scheinfeld",
        "Schelklingen",
        "Schenefeld",
        "Scheßlitz",
        "Schieder-Schwalenberg",
        "Schifferstadt",
        "Schillingsfürst",
        "Schiltach",
        "Schirgiswalde-Kirschau",
        "Schkeuditz",
        "Schkölen",
        "Schleiden",
        "Schleiz",
        "Schleswig",
        "Schlettau",
        "Schleusingen",
        "Schlieben",
        "Schlitz",
        "Schloß Holte-Stukenbrock",
        "Schlüchtern",
        "Schlüsselfeld",
        "Schmalkalden",
        "Schmallenberg",
        "Schmölln",
        "Schnackenburg",
        "Schnaittenbach",
        "Schneeberg",
        "Schneverdingen",
        "Schongau",
        "Schopfheim",
        "Schorndorf",
        "Schortens",
        "Schotten",
        "Schramberg",
        "Schraplau",
        "Schriesheim",
        "Schrobenhausen",
        "Schrozberg",
        "Schwaan",
        "Schwabach",
        "Schwabmünchen",
        "Schwaigern",
        "Schwalbach am Taunus",
        "Schwalmstadt",
        "Schwandorf",
        "Schwanebeck",
        "Schwarzatal",
        "Schwarzenbach a.Wald",
        "Schwarzenbach a.d.Saale",
        "Schwarzenbek",
        "Schwarzenberg",
        "Schwarzenborn",
        "Schwarzheide",
        "Schwedt",
        "Schweich",
        "Schweinfurt",
        "Schwelm",
        "Schwentinental",
        "Schwerin",
        "Schwerte",
        "Schwetzingen",
        "Schwäbisch Gmünd",
        "Schwäbisch Hall",
        "Schömberg",
        "Schönau im Schwarzwald",
        "Schönau",
        "Schönberg",
        "Schönebeck",
        "Schöneck",
        "Schönewalde",
        "Schöningen",
        "Schönsee",
        "Schönwald",
        "Schöppenstedt",
        "Schüttorf",
        "Sebnitz",
        "Seehausen",
        "Seeland",
        "Seelow",
        "Seelze",
        "Seesen",
        "Sehnde",
        "Seifhennersdorf",
        "Selbitz",
        "Selb",
        "Seligenstadt",
        "Selm",
        "Selters",
        "Sendenhorst",
        "Senden",
        "Senftenberg",
        "Seßlach",
        "Siegburg",
        "Siegen",
        "Sigmaringen",
        "Simbach am Inn",
        "Simmern",
        "Sindelfingen",
        "Singen",
        "Sinsheim",
        "Sinzig",
        "Soest",
        "Solingen",
        "Solms",
        "Soltau",
        "Sondershausen",
        "Sonneberg",
        "Sonnewalde",
        "Sonthofen",
        "Sontra",
        "Spaichingen",
        "Spalt",
        "Spangenberg",
        "Speicher",
        "Spenge",
        "Speyer",
        "Spremberg/Grodk",
        "Springe",
        "Sprockhövel",
        "Stadt Wehlen",
        "Stadtallendorf",
        "Stadtbergen",
        "Stadthagen",
        "Stadtilm",
        "Stadtlohn",
        "Stadtoldendorf",
        "Stadtprozelten",
        "Stadtroda",
        "Stadtsteinach",
        "Starnberg",
        "Staufen im Breisgau",
        "Staufenberg",
        "Stavenhagen",
        "Staßfurt",
        "Steinach",
        "Steinau an der Straße",
        "Steinbach-Hallenberg",
        "Steinbach",
        "Steinfurt",
        "Steinheim an der Murr",
        "Steinheim",
        "Stein",
        "Stendal",
        "Sternberg",
        "Stockach",
        "Stolberg",
        "Stollberg",
        "Stolpen",
        "Storkow",
        "Straelen",
        "Stralsund",
        "Strasburg",
        "Straubing",
        "Strausberg",
        "Strehla",
        "Stromberg",
        "Stutensee",
        "Stuttgart",
        "Stößen",
        "Stühlingen",
        "Suhl",
        "Sulingen",
        "Sulz am Neckar",
        "Sulzbach-Rosenberg",
        "Sulzbach",
        "Sulzburg",
        "Sundern",
        "Syke",
        "Sömmerda",
        "Südliches Anhalt",
        "Süßen",
        "Tambach-Dietharz",
        "Tangerhütte",
        "Tangermünde",
        "Tann (Rhön)",
        "Tanna",
        "Tauberbischofsheim",
        "Taucha",
        "Taunusstein",
        "Tecklenburg",
        "Tegernsee",
        "Telgte",
        "Teltow",
        "Templin",
        "Tengen",
        "Tessin",
        "Teterow",
        "Tettnang",
        "Teublitz",
        "Teuchern",
        "Teupitz",
        "Teuschnitz",
        "Thale",
        "Thalheim",
        "Thannhausen",
        "Tharandt",
        "Themar",
        "Thum",
        "Tirschenreuth",
        "Titisee-Neustadt",
        "Tittmoning",
        "Todtnau",
        "Torgau",
        "Torgelow",
        "Tornesch",
        "Traben-Trarbach",
        "Traunreut",
        "Traunstein",
        "Trebbin",
        "Trebsen",
        "Treffurt",
        "Trendelburg",
        "Treuchtlingen",
        "Treuenbrietzen",
        "Treuen",
        "Triberg im Schwarzwald",
        "Tribsees",
        "Trier",
        "Triptis",
        "Trochtelfingen",
        "Troisdorf",
        "Trossingen",
        "Trostberg",
        "Tuttlingen",
        "Twistringen",
        "Töging am Inn",
        "Tönisvorst",
        "TönningTønning / Taning",
        "Tübingen",
        "Uebigau-Wahrenbrück",
        "Ueckermünde",
        "Uelzen",
        "Uetersen",
        "Uffenheim",
        "Uhingen",
        "Ulmen",
        "Ulm",
        "Ulrichstein",
        "Ummerstadt",
        "Unkel",
        "Unna",
        "Unterschleißheim",
        "Usedom",
        "Usingen",
        "Uslar",
        "Vacha",
        "Vaihingen an der Enz",
        "Vallendar",
        "Varel",
        "Vechta",
        "Velbert",
        "Velburg",
        "Velden",
        "Velen",
        "Vellberg",
        "Vellmar",
        "Velten",
        "Verden an der Aller",
        "Veringenstadt",
        "Verl",
        "Versmold",
        "Vetschau/Wětošow",
        "Viechtach",
        "Viernheim",
        "Viersen",
        "Villingen-Schwenningen",
        "Vilsbiburg",
        "Vilseck",
        "Vilshofen an der Donau",
        "Visselhövede",
        "Vlotho",
        "Voerde",
        "Vogtsburg",
        "Vohburg a.d.Donau",
        "Vohenstrauß",
        "Volkach",
        "Volkmarsen",
        "Vreden",
        "Vöhrenbach",
        "Vöhringen",
        "Völklingen",
        "Wachenheim",
        "Wadern",
        "Waghäusel",
        "Wahlstedt",
        "Waiblingen",
        "Waibstadt",
        "Waischenfeld",
        "Waldbröl",
        "Waldeck",
        "Waldenbuch",
        "Waldenburg",
        "Waldenburg",
        "Waldershof",
        "Waldheim",
        "Waldkappel",
        "Waldkirchen",
        "Waldkirch",
        "Waldkraiburg",
        "Waldmünchen",
        "Waldsassen",
        "Waldshut-Tiengen",
        "Walldorf",
        "Walldürn",
        "Wallenfels",
        "Walsrode",
        "Waltershausen",
        "Waltrop",
        "Wanfried",
        "Wangen im Allgäu",
        "Wanzleben-Börde",
        "Warburg",
        "Warendorf",
        "Waren",
        "Warin",
        "Warstein",
        "Wassenberg",
        "Wasserburg am Inn",
        "Wassertrüdingen",
        "Wasungen",
        "Wedel",
        "Weener",
        "Wegberg",
        "Wegeleben",
        "Wehr",
        "Weida",
        "Weiden in der Oberpfalz",
        "Weikersheim",
        "Weil am Rhein, Germany",
        "Weil der Stadt",
        "Weilburg",
        "Weilheim an der Teck",
        "Weilheim in Oberbayern",
        "Weimar",
        "Weingarten",
        "Weinheim",
        "Weinsberg",
        "Weinstadt",
        "Weismain",
        "Weiterstadt",
        "Weißenberg/Wóspork",
        "Weißenburg in Bayern",
        "Weißenfels",
        "Weißenhorn",
        "Weißensee",
        "Weißenstadt",
        "Weißenthurm",
        "Weißwasser",
        "Welzheim",
        "Welzow/Wjelcej",
        "Wemding",
        "Wendlingen",
        "Werben",
        "Werdau",
        "Werder (Havel)",
        "Werdohl",
        "Werlte",
        "Werl",
        "Wermelskirchen",
        "Wernau",
        "Werneuchen",
        "Werne",
        "Wernigerode",
        "Werra-Suhl-Tal",
        "Wertheim",
        "Werther (Westf.)",
        "Wertingen",
        "Wesel",
        "Wesenberg",
        "Wesselburen",
        "Wesseling",
        "Westerburg",
        "Westerstede",
        "Wetter (Hessen)",
        "Wetter",
        "Wettin-Löbejün",
        "Wetzlar",
        "Widdern",
        "Wiehl",
        "Wiesbaden",
        "Wiesensteig",
        "Wiesloch",
        "Wiesmoor",
        "Wildau",
        "Wildberg",
        "Wildenfels",
        "Wildeshausen",
        "Wilhelmshaven",
        "Wilkau-Haßlau",
        "Willebadessen",
        "Willich",
        "Wilsdruff",
        "Wilster",
        "Wilthen",
        "Windischeschenbach",
        "Windsbach",
        "Winnenden",
        "Winsen",
        "Winterberg",
        "Wipperfürth",
        "Wirges",
        "Wismar",
        "Wissen",
        "Wittenberge",
        "Wittenberg",
        "Wittenburg",
        "Witten",
        "Wittichenau/Kulow",
        "Wittingen",
        "Wittlich",
        "Wittmund",
        "Wittstock",
        "Witzenhausen, Germany",
        "Woldegk",
        "Wolfach",
        "Wolfenbüttel",
        "Wolfhagen",
        "Wolframs-Eschenbach",
        "Wolfratshausen",
        "Wolfsburg",
        "Wolfstein",
        "Wolgast",
        "Wolkenstein",
        "Wolmirstedt",
        "Worms",
        "Wriezen",
        "Wunsiedel",
        "Wunstorf",
        "Wuppertal",
        "Wurzbach",
        "Wurzen",
        "Wustrow",
        "Wyk auf Föhra Wik / Vyk",
        "Wächtersbach",
        "Wörrstadt",
        "Wörth am Main",
        "Wörth am Rhein",
        "Wörth an der Donau",
        "Wülfrath",
        "Würselen",
        "Würzburg",
        "Xanten",
        "Zahna-Elster",
        "Zarrentin am Schaalsee",
        "Zehdenick",
        "Zeil am Main",
        "Zeitz",
        "Zell am Harmersbach",
        "Zell im Wiesental",
        "Zella-Mehlis",
        "Zell",
        "Zerbst",
        "Zeulenroda-Triebes",
        "Zeven",
        "Ziegenrück",
        "Zierenberg",
        "Ziesar",
        "Zirndorf",
        "Zittau",
        "Zossen",
        "Zschopau",
        "Zweibrücken",
        "Zwenkau",
        "Zwickau",
        "Zwiesel",
        "Zwingenberg",
        "Zwönitz",
        "Zörbig",
        "Zülpich",
        "Öhringen",
        "Östringen",
        "Übach-Palenberg",
        "Überlingen"
      ]
  return cities;
  }
  }
  